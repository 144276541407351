* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
:root {
  scroll-behavior: smooth;
  font-size: 62.5%;
}
.main-container {
  width: 100%;
  padding: 5px 0;
  font-family: "Rubik", sans-serif;
  background: #C8DCFF;
  color: rgb(12, 12, 12);
  overflow-x: hidden;
}
.main-header {
  width: 100%;

  background-image: url(./assets/header-assets/header-pattern1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 75px;
} 
.main-header-navbar{
  
  width: 100%;
  display: flex;
  font-weight: 500;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.main-header-navbar__logo {
  width: 40%;
  max-width: 160px;
  margin-right: auto;
}
.main-header-navbar__login {
  order: 1;
  width: 50%;
  max-width: 240px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.main-header-navbar__login__login-btn {
  width: 35%;
  color: #fff;
  font-size: 1.3rem;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-right: 1px solid rgba(255, 255, 255, 0.664);
  transition: color 0.3s ease-in;
}
.main-header-navbar__login__login-btn:hover {
  color: #92b2f3;
}
.main-header-navbar__login__login-btn:active {
  color: #fbc17c;
}
.main-header-navbar__login__register-btn {
  width: 60%;
  padding: 9px 10px;
  background-color: #3671e9;
  color: rgb(11, 10, 10);
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.3rem;
  max-width: 140px;
  transition: all 0.3s ease-in;
}
.main-header-navbar__login__register-btn:hover {
  background-color: #6491ee;
}
.main-header-navbar__login__register-btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-header-navbar__nav {
  margin-top: 15px;
  order: 2;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-header-navbar__nav__item {
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
}
.typewriter  {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  /* border-right: .15em solid orange; The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
   /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}
.ball {
  animation: bounce 1s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(1, 1, 1, 1);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
      transform: translate3d(0, 0, 0);
  }
  to {
      transform: translate3d(0, 100px, 0);
  }
}
/* Prefix Support */



@-webkit-keyframes bounce {
  from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
  to {
      -webkit-transform: translate3d(0, 200px, 0);
      transform: translate3d(0, 100px, 0);
  }
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: rgb(2, 2, 2);
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(.2s * var(--i))
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
  }
}
.main-header-navbar__nav__link {
  color: inherit;
  
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease-in;
}
.main-header-navbar__nav__link:hover {
  color: #92b2f3;
}
.main-header-navbar__nav__link:active {
  color: #fbc17c;
}
.main-header-content-principal {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-header-content-principal__title {
  margin-top: 40px;
  font-size: 3rem;
}
.main-header-content-principal__title span {
  color: #3b9af1;
}
.main-header-content-principal__description {
  font-size: 1.4rem;
  margin: 25px auto;
  font-weight: 400;
}
.main-header-content-principal__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-header-content-principal__btn:hover {
  background-color: #6491ee;
}
.main-header-content-principal__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-header-content-principal__btn i {
  margin-left: 10px;
  color: #3671e9;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
}
.main-header-content-principal__illustration {
  width: 100%;
}
.main-header-content-principal .discount-section {
  width: 80%;
  max-width: 230px;
  background-color: #3c308a;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 5px 0px;
  animation: beat 1s 2s ease-in-out alternate 15;
}
@keyframes beat {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(0.95);
  }
}
.main-header-content-principal .discount-section__discount {
  display: inline-block;
  padding: 5px;
  margin-left: 5px;
  border-radius: 30px;
  width: 30%;
  font-size: 1rem;
  background-color: #fff;
  color: #0d0d2b;
  font-weight: 500;
}
.main-header-content-principal .discount-section__description {
  font-size: 1rem;
  margin-right: 10px;
}
.main-header-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.header-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.main-content {
  width: 100%;
}
.main-content .why-us-wrapper {
  width: 100%;
  background-image: url(./assets/why-us-section-assets/why-us-pattern1.png),
    url(./assets/why-us-section-assets/why-us-pattern2.png),
    url(./assets/why-us-section-assets/why-us-pattern3.png);
  background-repeat: no-repeat;
  background-position: left center, right bottom, right top;
  background-size: 250px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}
.main-content .why-us-wrapper .stats-section {
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 75px;
}
.main-content .why-us-wrapper .stats-section__reference {
  width: 50%;
  margin-bottom: 40px;
  text-align: center;
}
.main-content .why-us-wrapper .stats-section__reference .fas {
  color: #09090a;
  background-color: #5aafc98c;
  font-size: 35px;
  padding: 20px;
  border-radius: 50%;
}
.flexDiv{
  display: flex;
  flex-direction: column;
  margin:0 auto;
  width:80%;
  border: 2px solid #0e111252;
 padding: 20px;
 border-radius: 30px;

}
.flexDivBtn{
  display: flex;
  justify-content: space-between;
}
.main-content .why-us-wrapper .stats-section__reference__title {
  font-size: 2.5rem;
  margin: 15px auto;
}
.main-content .why-us-wrapper .stats-section__reference__description {
  font-size: 1.4rem;
}
.main-content .why-us-wrapper .why-us-section {
  width: 80%;
  max-width: 400px;
  margin-bottom: 75px;
}
.main-content .why-us-wrapper .why-us-section__content {
  width: 100%;
  text-align: center;
}
.main-content .why-us-wrapper .why-us-section__content__title {
  font-size: 2.5rem;
}
.main-content .why-us-wrapper .why-us-section__content__title span {
  color: #cae4fb;
}
.main-content .why-us-wrapper .why-us-section__content__description {
  font-size: 1.4rem;
  margin: 20px auto;
}
.main-content .why-us-wrapper .why-us-section__content__btn {
  width: 100%;
  padding: 9px 10px;
  background-color: #5b8cee;
  color: rgb(19, 19, 19);
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  margin: 20px auto 10px;
  transition: all 0.3s ease-in;
  border:1px solid white;
}
.main-content .why-us-wrapper .why-us-section__content__btn:hover {
  background-color: #6491ee;
}
.main-content .why-us-wrapper .why-us-section__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .why-us-wrapper .why-us-section__illustration {
  width: 100%;
  animation-delay: 8s;
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
}
.main-content .why-us-wrapper .benefits-section {
  width: 80%;
  max-width: 400px;
  text-align: center;
}
.main-content .why-us-wrapper .benefits-section__title {
  font-size: 2.5rem;
}
.main-content .why-us-wrapper .benefits-section__title span {
  color: #3b9af1;
}
.main-content .why-us-wrapper .benefits-section__description {
  font-size: 1.4rem;
  margin-top: 30px;
}
.main-content .why-us-wrapper .benefits-section .card-info {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 25px 15px;
  transform: translateY(50%);
  text-align: left;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}
.main-content .why-us-wrapper .benefits-section .card-info__title {
  font-size: 1.4rem;
  color: #1181e8;
}
.main-content .why-us-wrapper .benefits-section .card-info__description {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 15px auto;
}
.main-content .why-us-wrapper .benefits-section .card-info__description span {
  color: #3b9af1;
}
.main-content .why-us-wrapper .benefits-section .card-info__advice {
  font-size: 1rem;
  color: #979797;
}
.main-content .cryptocurrencies-section {
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 120px 0 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-content .cryptocurrencies-section__title {
  font-size: 2.4rem;
  width: 80%;
  max-width: 500px;
  margin-bottom: 50px;
}
.main-content .cryptocurrencies-section .cryptocurrencies-info-cards {
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card {
  width: 100%;
  max-width: 290px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 0 15px;
  margin-bottom: 50px;
  border-radius: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3), 1px -1px 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card:hover {
  background-color: #09a5dd;
  color: #fff;
  transform: scale(1.05);
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card:hover
  p {
  color: #fff;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card:active {
  transform: scale(0.95);
  background-color: #4808bd;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card
  .fab {
  font-size: 80px;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card
  .fa-bitcoin {
  color: #f9aa4b;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card
  .fa-ethereum {
  color: #fff;
  padding: 10px 20px;
  font-size: 60px;
  border-radius: 50%;
  background-color: #1181e8;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__title {
  width: 100%;
  font-size: 2rem;
  margin: 30px auto 20px;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__title
  span {
  font-size: 1rem;
  font-weight: 400;
  color: #979797;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__description {
  font-size: 1.4rem;
  width: 70%;
  color: #646464;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  margin: 30px auto;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__btn
  i {
  margin-left: 10px;
  color: #3671e9;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
}
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
}

/* Card container */
.card {
  margin:0 auto;
  position: relative;
  max-width: 1000px;
  font-size:16px;
  color:white;
  background-color:transparent;
  border:none;
  
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
}
/* Global ::before */
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid rgb(22, 22, 22);
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
  left: 0px;
  top: -5.5px;
  bottom: -4.5px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

/* Information about the timeline */
.paddingCls{
  padding: 20px;
}
.info {
  display: flex;
  flex-direction: column;
  background: rgb(16 118 188);
  color: rgb(0, 0, 0);
  border-radius: 10px;
  padding: 10px;
}

/* Title of the card */
.title {
  color: rgb(14, 15, 15);
  position: relative;
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  border: 3px solid rgb(8, 181, 212);
}

/* text right if the card is even  */
.card:nth-child(even) > .info > .title {
  text-align: right;
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .title::before {
  left: -45px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .title::before {
  right: -45px;
}

.main-content .features-section {
  width: 100%;
  background: linear-gradient(180deg, #280766, #0d0d2b);
  background-image: url(./assets/features-section-assets/features-section-pattern1.png),
    url(./assets/features-section-assets/features-section-pattern2.png),
    url(./assets/header-assets/header-pattern1.svg);
  background-position: right bottom, left top, right center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-content .features-section__title {
  font-size: 2.4rem;
  width: 80%;
  max-width: 400px;
  margin-bottom: 75px;
}
.main-content .features-section .invest-smart-article,
.main-content .features-section .grow-profit-article,
.main-content .features-section .detailed-stats-article {
  width: 80%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 75px;
}
.main-content .features-section .invest-smart-article__content {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .features-section .invest-smart-article__content__title {
  font-size: 2rem;
}
.main-content .features-section .invest-smart-article__content__description {
  font-size: 1.4rem;
  width: 80%;
  margin: 25px auto;
}
.main-content .features-section .invest-smart-article__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-content .features-section .invest-smart-article__content__btn:hover {
  background-color: #6491ee;
}
.main-content .features-section .invest-smart-article__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .features-section .invest-smart-article__graphic {
  width: 100%;
}
.main-content .features-section .detailed-stats-article__content {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .features-section .detailed-stats-article__content__title {
  font-size: 2rem;
}
.main-content .features-section .detailed-stats-article__content__description {
  font-size: 1.4rem;
  width: 80%;
  margin: 25px auto;
}
.main-content .features-section .detailed-stats-article__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-content .features-section .detailed-stats-article__content__btn:hover {
  background-color: #6491ee;
}
.main-content .features-section .detailed-stats-article__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .features-section .detailed-stats-article__graphic {
  width: 100%;
}
.main-content .features-section .grow-profit-article__content {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .features-section .grow-profit-article__content__title {
  font-size: 2rem;
}
.main-content .features-section .grow-profit-article__content__description {
  font-size: 1.4rem;
  width: 80%;
  margin: 25px auto;
}
.main-content .features-section .grow-profit-article__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-content .features-section .grow-profit-article__content__btn:hover {
  background-color: #6491ee;
}
.main-content .features-section .grow-profit-article__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .features-section .grow-profit-article__graphic {
  width: 100%;
}
.cta-section {
  width: 80%;
  max-width: 400px;
  background-color: #1672fd;
  background-image: url(./assets/why-us-section-assets/why-us-pattern2.png),
    url(./assets/why-us-section-assets/why-us-pattern3.png);
  background-repeat: no-repeat;
  background-position: right bottom, left top;
  background-size: 150px;
  margin: -50px auto 75px;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.cta-section__content {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}
.cta-section__content__title {
  font-size: 2rem;
}
.cta-section__content__description {
  margin: 20px auto;
  font-size: 1.4rem;
  font-weight: 400;
}
.cta-section__subscribe {
  text-align: center;
}
.cta-section__subscribe__input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  padding: 10px 5px;
  transition: all 0.3s ease-in;
}
.cta-section__subscribe__input:hover {
  border-bottom: 2px solid #f9aa4b;
}
.cta-section__subscribe__input:focus {
  outline: none;
  color: #fff;
}
.cta-section__subscribe__input::placeholder {
  color: #fff;
  font-size: 1.2rem;
}
.cta-section__subscribe__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  background-color: #fff;
  color: #0d0d2b;
  font-weight: 500;
  transition: all 0.3s ease-in;
}
.cta-section__subscribe__btn:hover {
  background-color: #c8c8c8;
  color: #fff;
}
.cta-section__subscribe__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.footer-container {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.main-footer {
  width: 100%;
  background-color: #0d0d2b;
}
.main-footer__logo {
  width: 130px;
  margin-bottom: 35px;
}
.main-footer-navbar {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.main-footer-navbar__nav {
  width: 70%;
  list-style-type: none;
  margin-bottom: 35px;
}
.main-footer-navbar__nav__title {
  
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top:10px;
  color: #fff;
}
.main-footer-navbar__nav__item {
  margin-bottom: 10px;
}
.main-footer-navbar__nav__link {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  transition: color 0.3s ease-in;
}
.main-footer-navbar__nav__link:hover {
  color: #92b2f3;
}
.main-footer-navbar__nav__link:active {
  color: #fbc17c;
}
.main-footer .payment-systems {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.main-footer .payment-systems__title {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 30px;
  margin-top:20px;
  color:white;
}
.main-footer .payment-systems-icons {
  width: 80%;
  max-width: 340px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.main-footer .payment-systems-icons .fab {
  font-size: 50px;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in;
}
.main-footer .payment-systems-icons .fab:hover {
  color: #92b2f3;
}
.main-footer .payment-systems-icons .fab:active {
  color: #fbc17c;
}
.main-footer .copy-and-social {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.main-footer .copy-and-social__copy {
  text-align: center;
  width: 100%;
  font-weight: 400;
  margin-bottom: 25px;
}
.main-footer .copy-and-social .social-icons {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-footer .copy-and-social .social-icons .fab {
  color: #eee;
  font-size: 1.6rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s ease-in;
}
.main-footer .copy-and-social .social-icons .fab:hover {
  color: #92b2f3;
}
.main-footer .copy-and-social .social-icons .fab:active {
  color: #fbc17c;
}
.attribution {
  margin-top: 25px;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  text-align: center;
  font-size: 1.4rem;
  color: #0d0d2b;
  font-weight: 500;
}
.attribution a {
  display: inline-block;
  margin-left: 5px;
  color: #d77907;
}
@media screen and (min-width: 768px) {
  .header-container {
    max-width: 80%;
  }
  .main-header-navbar {
    justify-content: space-between;
  }
 
  .main-header-navbar__nav {
    width: 100%;
    max-width: 500px;
    margin-top: 0;
    order: 1;
  }
  .main-header-navbar__login {
    width: 30%;
  }
  .main-header-content-principal {
    width: 50%;
    justify-content: flex-start;
    text-align: left;
  }
  .main-header-content-principal__illustration {
    width: 50%;
  }
  .main-header-content-container {
    justify-content: space-between;
  }
  .main-content .why-us-wrapper .stats-section {
    margin-top: 50px;
    width: 90%;
    flex-flow: row nowrap;
  }
  .main-content .why-us-wrapper .stats-section__reference {
    width: 30%;
    margin-bottom: 0;
  }
  .main-content .why-us-wrapper .why-us-section {
    width: 90%;
    max-width: 750px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  .main-content .why-us-wrapper .why-us-section__content {
    width: 45%;
    order: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 25px 0;
  }
  .main-content .why-us-wrapper .why-us-section__illustration {
    width: 55%;
  }
  
  .main-content .why-us-wrapper .benefits-section {
    max-width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .main-content .why-us-wrapper .benefits-section__title {
    width: 60%;
  }
  .main-content .why-us-wrapper .benefits-section__description {
    width: 60%;
  }
  .main-content .why-us-wrapper .benefits-section .card-info {
    max-width: 50%;
  }
  .main-content .cryptocurrencies-section__title {
    width: 100%;
  }
  
  .main-content .cryptocurrencies-section .cryptocurrencies-info-cards {
    width: 95%;
    max-width: 1050px;
  }
  .main-content .features-section__title {
    font-size: 3rem;
    max-width: 580px;
  }
  .main-content .features-section .invest-smart-article,
  .main-content .features-section .detailed-stats-article,
  .main-content .features-section .grow-profit-article {
    justify-content: space-between;
    margin-bottom: 75px;
    
  }
  .wrapper {
    padding: 50px 100px;
  }
  
  .svg-container { 
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: middle; 
    overflow: hidden; 
    background: #f5f3e7;
  }
  
  .svg-content { 
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main-content .features-section .invest-smart-article__content {
    width: 45%;
    margin: 40px 0;
    justify-content: flex-start;
    text-align: left;
  }
  .main-content .features-section .invest-smart-article__content__title {
    font-size: 2.6rem;
  }
  .main-content .features-section .invest-smart-article__content__description {
    margin: 25px 0;
  }
  .main-content .features-section .invest-smart-article__graphic {
    width: 50%;
  }
  .main-content .features-section .detailed-stats-article {
    justify-content: space-between;
    margin-bottom: 75px;
  }
  .main-content .features-section .detailed-stats-article__content {
    width: 40%;
    margin: 40px 0;
    justify-content: flex-start;
    text-align: left;
  }
  .main-content .features-section .detailed-stats-article__content__title {
    font-size: 2.6rem;
  }
  .main-content
    .features-section
    .detailed-stats-article__content__description {
    margin: 25px 0;
  }
  .main-content .features-section .detailed-stats-article__graphic {
    width: 55%;
    order: -1;
  }
  .main-content .features-section .grow-profit-article {
    justify-content: space-between;
  }
  .main-content .features-section .grow-profit-article__content {
    width: 45%;
    margin: 40px 0;
    justify-content: flex-start;
    text-align: left;
  }
  .main-content .features-section .grow-profit-article__content__title {
    font-size: 2.6rem;
  }
  .main-content .features-section .grow-profit-article__content__description {
    margin: 25px 0;
  }
  .main-content .features-section .grow-profit-article__graphic {
    width: 55%;
  }
  .cta-section {
    max-width: 750px;
    justify-content: space-between;
  }
  .cta-section__content {
    width: 50%;
    margin: 0;
    text-align: left;
  }
  .cta-section__subscribe {
    width: 45%;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .footer-container {
    width: 90%;
    max-width: 800px;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .main-footer-navbar {
    width: 40%;
    align-items: flex-start;
  }
  .main-footer-navbar__nav {
    width: 50%;
  }
  .main-footer .payment-systems {
    width: 35%;
  }
  .main-footer .payment-systems-icons {
    width: 100%;
  }
  .main-footer .copy-and-social {
    justify-content: space-between;
  }
  .main-footer .copy-and-social__copy {
    width: 200px;
    margin-bottom: 0;
  }
  .main-footer .copy-and-social .social-icons {
    max-width: 200px;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .main-footer .copy-and-social .social-icons{
    justify-content: start;
  }
  .main-container {
    padding: 5px 0;
  }
  .header-container {
    max-width: 1200px;
  }
  .main-header-navbar__login__register-btn {
    padding: 15px 10px;
  }
  .main-header-content-principal__title {
    font-size: 4rem;
    line-height: 50px;
  }
  .main-header-content-principal__description {
    font-size: 1.8rem;
    line-height: 30px;
  }
  .main-header-content-principal__btn {
    padding: 15px;
  }
  .main-header-content-principal__illustration {
    width: 58%;
  }
  .main-content .why-us-wrapper {
    flex-flow: column nowrap;
  }
  .main-content .why-us-wrapper .stats-section {
    max-width: 1100px;
  }
  .main-content .why-us-wrapper .why-us-section {
    width: 80%;
    max-width: 1200px;
  }
  .main-content .why-us-wrapper .why-us-section__content {
    width: 40%;
  }
  .main-content .why-us-wrapper .why-us-section__content__title {
    font-size: 3.3rem;
  }
  .main-content .why-us-wrapper .why-us-section__content__description {
    font-size: 1.8rem;
    line-height: 25px;
  }
  .main-content .why-us-wrapper .why-us-section__content__btn {
    margin: 20px 0;
    padding: 15px 10px;
  }
  .main-content .why-us-wrapper .why-us-section__illustration {
    width: 60%;
  }
  .main-content .why-us-wrapper .benefits-section {
    flex-flow: column nowrap;
  }
  .main-content .why-us-wrapper .benefits-section__title {
    font-size: 3.3rem;
  }
  .main-content .why-us-wrapper .benefits-section__description {
    font-size: 1.7rem;
  }
  .main-content .why-us-wrapper .benefits-section .card-info {
    max-width: 650px;
    padding: 35px;
  }
  .main-content .why-us-wrapper .benefits-section .card-info__title {
    font-size: 1.8rem;
  }
  .main-content .why-us-wrapper .benefits-section .card-info__description {
    font-size: 2.3rem;
  }
  .main-content .why-us-wrapper .benefits-section .card-info__advice {
    font-size: 1.5rem;
  }
  .main-content .cryptocurrencies-section {
    flex-flow: column nowrap;
  }
  .main-content .cryptocurrencies-section__title {
    font-size: 3rem;
    margin: 50px auto;
  }
  .main-content .cryptocurrencies-section .cryptocurrencies-info-cards {
    max-width: 1150px;
  }
  .main-content .features-section {
    flex-flow: column nowrap;
  }
  .main-content .features-section__title {
    font-size: 3.5rem;
    max-width: 700px;
  }
  .main-content .features-section .invest-smart-article,
  .main-content .features-section .detailed-stats-article,
  .main-content .features-section .grow-profit-article {
    max-width: 1100px;
    justify-content: space-evenly;
    margin-bottom: 120px;
  }
  .main-content .features-section .invest-smart-article__content,
  .main-content .features-section .detailed-stats-article__content,
  .main-content .features-section .grow-profit-article__content {
    width: 35%;
    margin: 0;
  }
  .main-content .features-section .invest-smart-article__content__title,
  .main-content .features-section .detailed-stats-article__content__title,
  .main-content .features-section .grow-profit-article__content__title {
    font-size: 3rem;
  }
  .main-content .features-section .invest-smart-article__content__description,
  .main-content .features-section .detailed-stats-article__content__description,
  .main-content .features-section .grow-profit-article__content__description {
    font-size: 1.8rem;
  }
  .main-content .features-section .invest-smart-article__content__btn,
  .main-content .features-section .detailed-stats-article__content__btn,
  .main-content .features-section .grow-profit-article__content__btn {
    padding: 15px 10px;
  }
  .cta-section {
    max-width: 850px;
  }
  .cta-section__content {
    width: 30%;
  }
  .cta-section__subscribe {
    justify-content: space-evenly;
    width: 60%;
  }
  .cta-section__subscribe__input {
    width: 55%;
  }
  .cta-section__subscribe__btn {
    padding: 15px 10px;
  }
  .footer-container {
    max-width: 1200px;
  }
  .main-footer-navbar {
    max-width: 300px;
  }
  .main-footer .payment-systems {
    max-width: 300px;
  }
  .main-footer .payment-systems-icons {
    width: 80%;
  }
} /*# sourceMappingURL=main.css.map */
@media (max-width:900px) {
 
  .flexDivBtn{
  
    justify-content: space-evenly;
  }
  .main-header-navbar__logo {
    margin:0 auto;
  }
  .main-header-navbar{
    flex-direction: column;
  }
  .main-header-navbar__nav {

    width: 80%;

}
}
@media (max-width:600px) { 
  .main-footer .copy-and-social .social-icons{
    justify-content: space-evenly;
  }
  .disconnectButton{
    margin-top:10px !important;
  }
}
